import React from 'react';
import { Helmet } from "react-helmet"
import MenuBar from '../components/MenuBar';
import { graphql, navigate } from 'gatsby';
import ProjectPreview from '../components/ProjectPreview';
import get from 'lodash/get'
import { GatsbyImage } from 'gatsby-plugin-image';
import '../styles/inquiries.css'
import Dock from '../components/Dock';
import emailspin from '../assets/emailspin.gif'
import iboo from '../assets/1800.png'
import harddrive from '../assets/harddrive.png'

const inquiries = ({ }) => {

    return (
        <main>
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="theme-color" content="black" />
                <title>Inquiries - HYSG</title>
                <link rel="canonical" href="https://haveyoustoppedgrowing.com/" />
            </Helmet>
            <MenuBar />
            <div className='inquiries-container'>
                <div className='inquiries-window'>
                    <div className='inquiries-window-left'>
                        <div className='inquiries-window-bar'>
                            <div className='action-circles'>
                                <span className='action-circle red' />
                                <span className='action-circle yellow' />
                                <span className='action-circle green' />
                            </div>
                        </div>
                        <div className='today-caption-div'><span className='today-caption'>Today</span></div>
                        <div className='notes-previews'>
                            <div className='note-preview-div active'>
                                <span className='note-preview-title'>INQUIRIES</span>
                                <span><span className='note-preview-date'>12:36pm</span>  <span className='note-preview-caption'>email: haveyoustoppedgrowing@gmail.com...</span></span>
                            </div>
                            {/*<div className='note-preview-div'>
                                <span className='note-preview-title'>WORKED WITH</span>
                                <span><span className='note-preview-date'>12:19pm</span>  <span className='note-preview-caption'>apple...</span></span>
    </div>*/}
                        </div>

                    </div>
                    <div className='inquiries-window-right'>
                        <div className='note-inquiry'>
                            <h1>INQUIRIES</h1>
                            <p>email: <a href="mailto:haveyoustoppedgrowing@gmail.com"><u>haveyoustoppedgrowing@gmail.com</u></a></p>
                            <p><a href="mailto:haveyoustoppedgrowing@gmail.com"><img src={emailspin} className='img-email' /></a></p>
                            <p>portfolio:</p>
                            <p><a href="/portfolio"><img src={harddrive} className='img-harddrive' /></a></p>
                            <p>telephone:</p>
                            <p><img src={iboo} className='img-1800' /></p>
                        </div>
                    </div>
                </div>
            </div>
            <Dock />
        </main >
    );
};

export default inquiries;